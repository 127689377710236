.container .removeIcon {
    margin-left: auto;
    opacity: 0.3;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.container:hover .removeIcon {
    opacity: 1;
}