.itemContainer {
}

.controls {
    margin-left: auto;
}

.scenarioControls {
}

.publicAccessUnlockedIcon, .removeButton, .editButton, .settingsIcon, .startButton {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
    opacity: 0.3;
}

.publicAccessUnlockedIcon{
    vertical-align: middle;
    margin-left: 5px;
}

.removeButton {
    vertical-align: middle;
    color: #3990ff;
    margin-left: 5px;
    cursor: pointer;
}

.editButton {
    vertical-align: middle;
    cursor: pointer;
    margin-left: 5px;
    color: #3990ff;
    position: relative;
    top: 1px;
}

.viewIcon {
    top: -1px;
}

.startButton {
    vertical-align: middle;
    color: #3990ff;
    margin-left: 5px;
    cursor: pointer;
}

.settingsIcon {
    vertical-align: middle;
    color: #3990ff;
    margin-left: 5px;
    cursor: pointer;
}

.controls .createButton {
    margin-left: auto;
}

.itemChartSkeleton {
    margin-top: -4px;
}

.itemContainer:hover .editButton,
.itemContainer:hover .publicAccessUnlockedIcon,
.itemContainer:hover .settingsIcon,
.itemContainer:hover .removeButton,
.itemContainer:hover .startButton {
    opacity: 1;
}

.itemKey {
    margin-right: 10px;
}

.itemKey:last-child {
    margin-right: 0;
}

.itemKeysContainer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.itemStatus {
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 2px solid;
    border-radius: 50%;
    margin-top: 6px;
    border-color: darkgray;
}

.itemStatusEnabled {
    border-color: #0fa34d;
}
