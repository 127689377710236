:root {
    --primary-background-color: #096bde;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #edeef0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiModal-root .noselect,
#root .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#root .inline {
    display: inline-block;
}

#root .nooutline {
    outline: none;
}

#root .nooutline:focus,
#root .nooutline:active {
    outline: none;
}

.Toastify__toast-container .Toastify__toast {
    border-radius: 8px;
    font-size: 15px;
    font-family: unset;
    min-height: unset;
    margin-bottom: 13px;
}

.Toastify__toast-container .Toastify__close-button {
    display: none;
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container .Toastify__toast {
        border-radius: unset;
        margin-bottom: unset;
    }
}

.ConstructorControlsCreateNodeMenuContainer {
    margin-top: 10px !important;
}

.Wizard__step .indicator {
    cursor: pointer;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #d4ddeb;
}

.Wizard__step .indicator.finished {
    width: 13px;
    height: 13px;
    background-color: #0fa34d;
}

.Wizard__step .indicator.active {
    border: 2px solid;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: transparent;
    border-color: var(--primary-background-color);
}
