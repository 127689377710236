.Node__headerContainer {
    border-bottom: 1px solid #eee;
    overflow: hidden;
    border-top-left-radius: 4px;
}

.Node__header {
    padding: 10px;
    display: flex;
    align-items: center;
}

.CommentNode {
    cursor: default;
}

.CommentNode .Node__removeButton {
    opacity: 0.5;
}

.CommentNode .NodeDragHandle svg {
    font-size: 24px;
    opacity: 0.7;
}

.CommentNode .NodeDragHandle,
.CommentNode .Node__removeButton {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.CommentNode:hover .NodeDragHandle,
.CommentNode:focus .NodeDragHandle,
.CommentNode:hover .Node__removeButton,
.CommentNode:focus .Node__removeButton {
    visibility: visible;
    opacity: 0.5;
}

.Node__headerMetrics {
    margin-left: auto;
    display: flex;
    vertical-align: middle;
    cursor: pointer;
}

.Node__headerNodeTitle {
    cursor: pointer;
}

.Node__container {
    cursor: default;
    width: 330px;
    border: 1px solid #eee;
    border-radius: 5px;
    background: white;
}

.Node__container.RandomSelectionNode {
    width: 370px;
}

.Node__container.ConditionNode {
    width: 360px;
}

.Node__content {
    padding: 15px;
}

.Node__headerMetrics svg {
    margin-right: 3px;
}

.Node__headerMetrics .Node__headerMetricsTitle {
    display: inline-block;
}

.NodeDragHandle {
    cursor: grab;
}

.NodeDragHandle svg {
    font-size: 30px;
    vertical-align: middle;
    color: gray;
}

.NodeDragHandle:active {
    cursor: grabbing;
}

.Node__headerButton {
    display: flex;
    cursor: pointer;
    align-items: center;
    opacity: 0;

    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.react-flow__node:hover .Node__headerButton {
    opacity: 1;
}

.Node__removeButton svg {
    font-size: 23px;
    color: gray;
}

.Node__sourceHandle {
    background-color: white;
    border: 2px solid var(--joy-palette-neutral-400);
    border-radius: 100%;
    width: 18px;
    height: 18px;
    top: unset;
    bottom: -16px;
}

.Node__targetHandle {
    top: 33px;
    left: -1px;
    width: 0;
    height: 49px;
    border-radius: 2px;
    border: 2px solid transparent;
    border-right: none;
    border-top: none;
    border-bottom: 0;
    background: transparent;
}

.ConditionNode_sourceHandleYes,
.ConditionNode_sourceHandleNo {
    right: -7px;
}

.ConditionNode_sourceHandleYes {
    border-color: var(--joy-palette-success-400);
    bottom: 27px;
}

.ConditionNode_sourceHandleNo {
    border-color: var(--joy-palette-danger-400);
    bottom: 1px;
}

.ConditionNode__variant {
    margin-top: 12px;
}
