.FormInput__passwordShowButton {
    color: var(--joy-palette-neutral-400);
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    margin-right: -7px;
}

.FormInput__passwordShowButton:hover {
    background: var(--joy-palette-primary-softBg);;
    color: var(--joy-palette-primary-softColor);
}

.FormInput__passwordShowButton--icon {
    display: block;
}