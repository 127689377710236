.Edges__RemovableEdge {
    background-color: white;
    color: gray;
    border: 1px solid #eee;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
}

.Edges__RemovableEdge .Edges__RemovableEdgeIcon {
    font-size: 14px;
    position: relative;
    top: -3px;
}
