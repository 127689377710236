.removeButton {
    opacity: 0;

    -webkit-transition: opacity 0.25s linear;
    -moz-transition: opacity 0.25s linear;
    -ms-transition: opacity 0.25s linear;
    -o-transition: opacity 0.25s linear;
    transition: opacity 0.25s linear;
}

.conditionContainer:hover .removeButton {
    opacity: 1;
}
