.MessageNode__imageUploadButton {
    width: 100%;
}

.MessageNode__files .MessageNode__imageUploadButton--disabled {
    cursor: not-allowed;
}

.MessageNode__files input[type=file] {
    display: none;
    position: absolute;
    opacity: 0;
}

.MessageNode__files img {
    border-radius: 8px;
}

.MessageNode__fileImage {
    cursor: pointer;
}

.MessageNode__imagesDeleteButton {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 5px;
    right: 5px;
    opacity: 0;

    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
}

.Node__container:hover .MessageNode__imagesDeleteButton,
.Node__container:active .MessageNode__imagesDeleteButton {
    opacity: 1;
}
