.MessageNodeKeyboardContainer {
    white-space: nowrap;
}

.MessageNodeKeyboardContainer--not_empty {
    padding-top: 15px;
}

.MessageNodeKeyboardButtonContainer {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.MessageNodeKeyboardButton {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
}

.MessageNodeKeyboardRow__sortable {
    display: inline-block;
    width: 100%;
}

.MessageNodeKeyboardRow .MessageNodeKeyboardRow__drag,
.MessageNodeKeyboardRow__addButton {
    -webkit-transition: opacity 0.3s linear;
    -moz-transition: opacity 0.3s linear;
    -ms-transition: opacity 0.3s linear;
    -o-transition: opacity 0.3s linear;
    transition: opacity 0.3s linear;
    /*opacity: 0;*/
}

.MessageNodeKeyboardRow .MessageNodeKeyboardRow__drag {
    color: gray;
    margin-right: 5px;
}

.MessageNodeKeyboardContainer:hover .MessageNodeKeyboardRow__drag,
.MessageNodeKeyboardContainer:active .MessageNodeKeyboardRow__drag,
.MessageNodeKeyboardContainer:hover .MessageNodeKeyboardRow__addButton,
.MessageNodeKeyboardContainer:active .MessageNodeKeyboardRow__addButton {
    opacity: 1;
}

.MessageNodeKeyboardRow .MessageNodeKeyboardRow__drag {
    cursor: grab;
}

.MessageNodeKeyboardRow .MessageNodeKeyboardRow__drag:active {
    cursor: grabbing;
}

.MessageNodeKeyboardRow {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.MessageNodeKeyboardRow:first-child {
    margin-top: 0;
}

.MessageNodeKeyboardButton:last-child {
    margin-right: 0;
}

.MessageNodeKeyboardButton__externalLink {
    display: inline-block;
    vertical-align: sub;
}

.MessageNodeKeyboardRightHandlers {
    display: inline-block;
    margin-right: -6px;
    position: relative;
    right: -7px;
    top: 3px;
    vertical-align: middle;
}

.MessageNodeKeyboardInlineToggle {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
}

.ButtonsSwitch__container.MessageNodeKeyboardInlineToggle--extraTopMargin {
    margin-top: 22px;
}
