.PauseNode__input {
    margin-top: 15px;
}

.PauseNode__input input[type='number'] {
    -moz-appearance:textfield;
}
.PauseNode__input input::-webkit-outer-spin-button,
.PauseNode__input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
